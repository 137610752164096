import $ from 'jquery';

import { $Elements, Breakpoints, Classes, Events } from './constants';
import { disableBodyScroll, enableBodyScroll } from './helpers';

$Elements.document.on(Events.page.enter, () => {

	// ---------- Menu ----------

	$Elements.PageWrapper.on('click', '.menu-toggle, a[href="#menu-wrapper"], .menu-overlay', function(e) {
		e.preventDefault();

		if($Elements.body.hasClass(Classes.menuOpen)) {
			$Elements.body.removeClass(Classes.menuOpen);
			$('.header-menu-wrapper > .menu > .menu-item').removeClass(Classes.active);
			enableBodyScroll();
		}
		else {
			$Elements.body.addClass(Classes.menuOpen);
			disableBodyScroll();
		}
	});

	$Elements.PageWrapper.on('click', '.header-menu-wrapper > .menu', function(e) {

		if(e.target !== e.currentTarget) return;

		if(window.innerWidth < Breakpoints.menu) {
			e.preventDefault();
			$('.menu-toggle').trigger('click');
		}
	});

	$Elements.PageWrapper.on('click', '.header-menu-wrapper > .menu > .menu-item .submenu-btn', function(e) {

		if(window.innerWidth < Breakpoints.menu) {
			e.preventDefault();

			if(!$(this).hasClass('close')) {
				$('.header-menu-wrapper > .menu > .menu-item').removeClass(Classes.active);
			}

			$(this).closest('.menu-item').toggleClass(Classes.active);
		}
	});
});

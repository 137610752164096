import $ from 'jquery';

import { $Elements, Classes, Elements, Events } from '../global/constants';
import { ajax } from '../global/helpers';

// ---------- Ajax Posts ----------

if(typeof ajax_posts_params !== 'undefined') {

	function initForm(form) {

		const searchPostTypes = ['post', 'page', 'placement', 'programme'];
		const page = form.find('input[name=page]');
		const filters = form.find('input[type=checkbox], input[type=radio], select');
		const ajaxTeamFilters = form.find('label.btn-link');
		const button = form.find('button.ajax-posts-btn');
		const newSearch = form.find('button.new-search');
		const content = form.find('.ajax-content');
		const noResults = form.find('.no-results');
		const resetButton = form.find('.actions button[type=reset]');
		const countWrapper = form.find('.actions .count');
		const count = form.find('.actions .count .total');
		const searchCount = form.find('.ajax-search-filters');

		button.on('click', () => form.data('append', true));

		newSearch.on('click', () => refreshPosts(form));
		filters.on('change', () => refreshPosts(form));

		ajaxTeamFilters.each(function() {
			$(this).on('click', () => {
				ajaxTeamFilters.removeClass('active');
				$(this).addClass('active');
			});
		});

		resetButton.on('click', () => refreshPosts(form, true));

		form.on('submit', async e => {
			e.preventDefault();
			e.stopImmediatePropagation();
			e.stopPropagation();

			button.attr('disabled', true);
			Elements.LoadingBar.classList.add(Classes.loading);

			const formData = new FormData(form[0]);
			formData.append('action', ajax_posts_params.action);
			formData.append('nonce', ajax_posts_params.nonce);

			try {

				const response = await ajax({
					url: ajax_posts_params.admin_url,
					type: 'POST',
					dataType: 'json',
					data: formData,
					processData: false,
					contentType: false,
				});

				if(response.data.content) {

					noResults.addClass(Classes.hidden);

					if(!form.data('append')) {
						content.empty();
					}

					content.append($(response.data.content));
					content.removeClass(Classes.hidden);
					count.text(response.data.found_posts);
					countWrapper.removeClass(Classes.hidden);
					searchCount.removeClass(Classes.hidden);

					if(searchCount.length > 0) {
						searchPostTypes.forEach(item => $('.count-' + item).html('<span>' + response.data.search_post_type_results[item] + '</span>'));
					}
				}
				else {
					content.addClass(Classes.hidden);
					noResults.removeClass(Classes.hidden);
				}

				if(response.data.next_page === 0) {
					button.addClass(Classes.hidden);
					button.attr('disabled', true);

					count.text(response.data.found_posts);
					countWrapper.removeClass(Classes.hidden);

					if(searchCount.length > 0) {
						searchCount.removeClass(Classes.hidden);
					}
				}
				else {
					count.text(response.data.found_posts);
					countWrapper.removeClass(Classes.hidden);
					page.val(response.data.next_page);
					button.removeClass(Classes.hidden);

					if(searchCount.length > 0) {
						searchCount.removeClass(Classes.hidden);
					}
				}
			}
			catch(e) {
				console.log(e);
				content.addClass(Classes.hidden);
				noResults.removeClass(Classes.hidden);
			}

			button.attr('disabled', false);
			Elements.LoadingBar.classList.remove(Classes.loading);

			return false;
		});
	}

	function refreshPosts(form, reset = false) {

		const page = form.find('input[name=page]');

		if(reset) {
			const filters = form.find('input[type=checkbox], input[type=radio], select');
			filters.prop('checked', false);
		}

		form.data('append', false);
		page.val(1);
		form.submit();
	}

	$('form.ajax-posts-form').each(function() {

		if(!$('.search-form-section').length) {

			initForm($(this));

			$Elements.document.on(Events.page.loaded, () => {
				refreshPosts($(this));
			});
		}
	});
}

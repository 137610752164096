import $ from 'jquery';
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/bgset/ls.bgset';
import GLightbox from 'glightbox';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { $Elements, Animations, Durations, Events } from './constants';
import { fixMobileHover, initGTM, isTouchScreen, setCookiesAllowed, setDisclaimerAccepted, smoothScrollTo, sortAnimations } from './helpers';

// ---------- LazyLoad Config ----------

lazySizes.cfg.throttleDelay = 66;
lazySizes.cfg.preloadAfterLoad = true;

// ---------- Fix Scroll ----------

history.scrollRestoration = "manual";

// ---------- Touch Screen ----------

if(isTouchScreen()) {
	$Elements.html.addClass('touchscreen');
}

// ---------- Fix VH ----------

$Elements.window.on('resize', () => document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`));
$Elements.window.trigger('resize');

// ---------- jQuery Related ----------

$Elements.document.on(Events.page.enter, () => {

	// ---------- Fix Mobile Hover ----------

	fixMobileHover();

	// ---------- Handle Aniamtions ----------

	ScrollTrigger.matchMedia(sortAnimations(Animations));

	// ---------- Links ----------

	$Elements.PageWrapper.on('click', 'a[href="#"], .void-link', (e) => e.preventDefault());

	$Elements.PageWrapper.on('click', '[data-scroll-to], [target="scroll-to"]', function(e) {
		e.preventDefault();

		const self = $(this);
		const target = (self.is('a')) ? $(self.attr('href')) : $(self.data('scroll-to'));

		if(target.length) {
			smoothScrollTo(target, Durations.animations.s);
		}
	});

	$Elements.PageWrapper.on('click', '.select-dropdown .dropdown-menu .btn', function() {
		$(this).closest('.dropdown').find('.dropdown-toggle').html($(this).text());
	});

	// ---------- GLightbox ----------

	$('[target="lightbox"]').each(function() {

		if($(this).attr('data-size') === 'full') {

			GLightbox({
				selector: 'a[href="' + this.attributes.href.value + '"][target="lightbox"][data-size="full"]',
				openEffect: 'fade',
				closeEffect: 'fade',
				width: 'auto',
				height: 'auto',
				videosWidth: 1280,
				skin: 'fullscreen',
			});
		}
		else {

			GLightbox({
				selector: 'a[href="' + this.attributes.href.value + '"][target="lightbox"]',
				openEffect: 'fade',
				closeEffect: 'fade',
				videosWidth: 1280,
			});
		}
	});

	$('[target^="lightbox-gallery"]').each(function() {

		if($(this).attr('data-size') === 'full') {

			GLightbox({
				selector: 'a[target="' + this.attributes.target.value + '"][data-size="full"]',
				openEffect: 'fade',
				closeEffect: 'fade',
				width: 'auto',
				height: 'auto',
				videosWidth: 1280,
				skin: 'fullscreen',
			});
		}
		else {

			GLightbox({
				selector: 'a[target="' + this.attributes.target.value + '"]',
				openEffect: 'fade',
				closeEffect: 'fade',
				videosWidth: 1280,
			});
		}
	});

	// ---------- Disclaimer ----------

	$('#disclaimer').on('close.bs.alert', function() {
		setDisclaimerAccepted();
		$Elements.body.removeClass('disclaimer-open');
	});

	// ---------- Cookies ----------

	//initGTM();

	$('#cookies-allowed').on('click', '[data-bs-dismiss="alert"]', function() {
		setCookiesAllowed(this.dataset.allowed);
	});

	// ---------- Modals ----------

	$('.modal').click(function(event) {
		$(event.target).modal('hide');
	});

	if($('#informations-modal').length) {
		$('#informations-modal').modal('toggle');
	}
});

import $ from 'jquery';
import { gsap } from 'gsap';

import { $Elements, Breakpoints, Classes, Events } from './constants';

$Elements.document.on(Events.page.enter, () => {

	// ---------- GTM Tracking ----------

	if(typeof dataLayer !== 'undefined') {

		function getCardTitle(layout, link) {

			let title = 'NC';
			const isSection = $(link).is('section');

			if(isSection) {
				const hn = $(link).find('h1, h2, h3, h4, h5');
				title = (hn.length) ? hn[0].innerText : title;
			}
			else {

				if($(link).attr('title') && $(link).attr('title').length) {
					title = $(link).attr('title');
				}

				if(
					layout === 'fake_products_grid' ||
					layout === 'fake_products_slider'
				) {

					const hn = $(link).find('div.title');
					title = (hn.length) ? hn[0].innerText : title;
				}
				else if(layout === 'cta_full_width') {

					const section = $(link).closest('.content-section');

					if(section.length) {
						const hn = section.find('div.title');
						title = (hn.length) ? hn[0].innerText : title;
					}
				}
				else if(
					layout === 'text_image_slider' ||
					layout === 'related_posts'
				) {

					const slide = $(link).closest('.content-column');

					if(slide.length) {
						const hn = slide.find('h2, h3, h4, h5');
						title = (hn.length) ? hn[0].innerText : title;
					}
				}
				else if(
					layout === 'cta_reservation_boxed' ||
					layout === 'simple_text' ||
					layout === 'simple_text_image' ||
					layout === 'text_phone_cta' ||
					layout === 'text_table' ||
					layout === 'featured_video' ||
					layout === 'featured_images' ||
					layout === 'multiple_blocs' ||
					layout === 'expert_advice'
				) {

					const section = $(link).closest('.content-section');

					if(section.length) {
						const hn = section.find('h2, h3');
						title = (hn.length) ? hn[0].innerText : title;
					}
				}
			}

			return (title.length > 30 && title !== 'NC') ? title.slice(0, 30) + '...' : title;
		}

		function dataLayerEvent(event, trigger) {

			const modal = ($(trigger).is('a')) ? $(trigger.hash) : $($(trigger).attr('data-bs-target'));

			let label = 'NC';

			if($(trigger).attr('title') && $(trigger).attr('title').length) {
				label = $(trigger).attr('title');
			}

			let section = $(trigger);

			if($(trigger).is('a')) {
				section = $(trigger).closest('.content-section')
			}
			else if($(trigger).hasClass('landing-page-forms-toggle')) {
				section = $Elements.document.find('.landing-page-forms');
			}
			else if($(trigger).is('button')) {
				section = $(trigger).closest('.landing-page-forms');
			}

			const header = $(trigger).closest('.header-wrapper');
			const footer = $(trigger).closest('.footer-wrapper');

			let layout = 'NC';
			let position = 'NC';

			if(section.length) {
				layout = section.data('layout');
				position = section.data('position');
			}
			else if(header.length) {
				layout = 'menu';
			}
			else if(footer.length) {
				layout = 'footer';
			}

			const title = getCardTitle(layout, trigger);

			if(event === 'click_cta' && modal.length) {
				modal.attr('data-cta-label', $(trigger).attr('title'));
				modal.attr('data-cta-layout', layout);
				modal.attr('data-cta-position', position);
				modal.attr('data-cta-title', title);
			}

			dataLayer.push({
				'event': event,
				'form_id': (modal.length) ? modal.data('form-id') : 'NC',
				'form_type': (modal.length) ? modal.data('form-type') : 'NC',
				'cta_label': label,
				'cta_section_layout': layout,
				'cta_section_position': position,
				'cta_card_title': title,
			});
		}

		// ---------- Click CTA ----------

		$Elements.document.on('click', 'a[href*="#form-modal"], button[data-bs-target*="#form-modal"]', function() {
			dataLayerEvent('click_cta', this);
		});

		// ---------- View CTA ----------

		$('a[href*="#form-modal"], button[data-bs-target*="#form-modal"]').each(function() {

			gsap.timeline({
				scrollTrigger: {
					trigger: this,
					once: true,
					start: 'bottom bottom',
					onEnter: () => dataLayerEvent('view_cta', this)
				}
			});
		});

		// ---------- View Layout ----------

		$('.content-section').each(function() {

			const cta = this.querySelector('a[href*="#form-modal"], button[data-bs-target*="#form-modal"]');

			if(cta) {

				gsap.timeline({
					scrollTrigger: {
						trigger: this,
						once: true,
						start: 'top 75%',
						onEnter: () => dataLayerEvent('view_cta_layout', this)
					}
				});
			}
		});

		$('.landing-page-forms-toggle').each(function() {
			dataLayerEvent('view_cta_layout', this);
		});

		// ---------- Click Phone ----------

		$Elements.document.on('click', 'a[href*="tel:"]', function() {
			dataLayerEvent('click_phone', this);
		});

		// ---------- Click Menu ----------

		$Elements.HeaderWrapper.on('click', '.menu a', function() {
			dataLayerEvent('click_menu', this);
		});
	}
});
